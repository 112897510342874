import getParsedContentState from '@/editor/utils/getParsedContentState';

/**
 * @param {string} [rawContentState]
 * @returns {string}
 */
export default function getTitlePlainText(rawContentState) {
  const title = getParsedContentState(rawContentState);

  return title?.blocks?.[0]?.text || '';
}
