import tryParseJson from '@/storychief/utils/tryParseJson';

/**
 * @param {string} [stringifiedContentState]
 * @returns {string}
 */
export default function getParsedContentState(stringifiedContentState) {
  const tryParseTitle = tryParseJson(stringifiedContentState);

  return tryParseTitle.valid ? tryParseTitle.parsed : '';
}
